// Markup & Styles
import "./projects-list-2.styl";
import reactTemplate from "./react-project-list.pug";

// Base & Utils
import { App } from "../app";
import { router } from "@/lib/router";
import type { Observable, ObservableArray, Subscription } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import * as BrowserStorageUtils from "@/lib/utils/browser-storage";
import { DateUtils } from "@/lib/utils/date";
import { Format } from "@/lib/utils/format";
import {
   buildDateFilterInstance,
   serializedFilters,
   serializeLegacyFilters,
} from "@/lib/utils/chip-filter-helper";
import { ValidationUtils } from "@/lib/utils/validation";
import { accumulateCustomFieldChipFilters } from "@/lib/utils/custom-field-helper";
import { createProjectRolesFilterOption } from "@/lib/utils/project-role-helper";

// Filters
import type { Filter, LabeledFilterOptions } from "@/lib/components/chip-filter/chip-filter";
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator";

// Auth & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { DefaultStore, defaultStore } from "@/stores/default-store";
import type { GetGroupEntitiesData, GetProjectAppliedRoleOptionsData } from "@/stores/group-store";
import type { IntegratedField } from "@/stores/company-store";
import { LoadingState } from "@/lib/components/grid/grid-store";
import { ProjectsList2GridStore } from "./projects-list-2-grid-store";

// Core-Api stores
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { PersonStore } from "@/stores/person-store.core";
import { TagStore } from "@/stores/tag-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { CompanyStore } from "@/stores/company-store.core";

// Legacy Stores
import { PeopleStore } from "@/stores/people-store";
import type { GetFilteredProjectsQuery } from "@/stores/project-store";
import { groupStore as legacyGroupStore } from "@/stores/group-store";

// Modules, API, & Schemas
import { ReportType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { PROJECTS } from "@laborchart-modules/common/dist/rethink/schemas/projects";
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type {
   CreateProjectPayload,
   CreateProjectResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/create-project";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type {
   FindProjectsPaginatedQueryParams,
   SerializedProjectListProject,
   SerializedProjectListTagInstance,
} from "@laborchart-modules/lc-core-api/dist/api/projects/find-projects";
import type {
   IndexedFindProjectsSortBy,
   NonIndexedFindProjectsSortBy,
} from "@laborchart-modules/common/dist/reql-builder/procedures/find-projects";
import type { SerializedWageOverride } from "@laborchart-modules/common/dist/rethink/serializers/wage-override-serializer";

// Models
import { PermissionLevel } from "@/models/permission-level";
import type { ColumnHeaderData } from "@/models/column-header";
import { ColumnHeader } from "@/models/column-header";
import { ValueSet } from "@/models/value-set";

// Grid & Editors
import { CheckboxColumnGroupManager } from "@/lib/components/grid/column-groups/checkbox-column-group-manager";
import type {
   ColumnTemplate,
   ProjectRole,
   ProjectRolesConfig,
} from "@/lib/components/grid/grid-column-manager";
import { GridColumnManager } from "@/lib/components/grid/grid-column-manager";
import type { GridCellFocus, GridSortOrder } from "@/lib/components/grid/virtual-grid/virtual-grid";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { createColumnGroupForEach } from "@/lib/components/grid/grid-column-group";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { LinkedTextCell } from "@/lib/components/grid/cells/linked-text-cell";
import { TagExpirationState } from "@/lib/components/tags/tag-chip";
import { TagsCell } from "@/lib/components/grid/cells/tags-cell";
import { ColorCell } from "@/lib/components/grid/cells/color-cell";
import { GridCursorState, isActionableCursor } from "@/lib/components/grid/grid-column";
import { MultilineTextCell } from "@/lib/components/grid/cells/multiline-text-cell";
import { ColumnWidthDefault, ColumnWidthMin } from "@/lib/components/grid/column-defaults";
import { GroupsEditor } from "@/lib/components/editors/groups-editor/groups-editor";
import { DisabledGroupsEditor } from "@/lib/components/editors/disabled-groups-editor/disabled-groups-editor";
import { LinkToProcoreCell } from "@/lib/components/grid/cells/link-to-procore-cell";

// Modals
import { Modal } from "@/lib/components/modals/modal";
import { CreateProjectPane } from "@/lib/components/modals/create-project-pane";
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";

// Managers
import {
   notificationManagerInstance,
   Notification,
   Icons,
} from "@/lib/managers/notification-manager";
import { modalManager } from "@/lib/managers/modal-manager";
import { Flag } from "@/flags";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { BatchDeleteValidator } from "@/lib/components/batch-actions/batch-delete/batch-delete";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import renderReactComponent from "@/react/render-react-component";
import type { Root } from "react-dom/client";
import { ListViewExportModalPane } from "@/lib/components/modals/list-view-export-modal-pane";

const ITEMS_PER_REQUEST = 40;

export class ProjectsList2 extends PageContentViewModel {
   readonly allowExportingData = authManager.checkAuthAction(
      PermissionLevel.Action.ALLOW_EXPORTING_DATA,
   );
   readonly canCreateProjects = authManager.checkAuthAction(PermissionLevel.Action.CREATE_PROJECT);
   readonly canEditProjects = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PROJECT_DETAILS,
   );
   readonly canDeleteProjects = authManager.checkAuthAction(PermissionLevel.Action.DELETE_PROJECT);
   readonly canViewProjectTags = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PROJECT_TAGS,
   );
   readonly canEditProjectTags = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PROJECT_TAGS,
   );
   readonly canEditProjectDetails = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PROJECT_DETAILS,
   );
   readonly canViewSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PROJECT_SENSITIVE,
   );
   readonly canEditSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PROJECT_SENSITIVE,
   );
   readonly canEditProjectRoles = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PROJECT_ROLES,
   );
   readonly hasBatchEditPermssions =
      this.canEditProjectDetails || this.canEditProjectTags || this.canEditProjectRoles;

   // Grid customization.
   readonly store: Observable<ProjectsList2GridStore>;
   readonly sortOrder = observable<GridSortOrder>({
      columnKey: "name",
      direction: Order.ASCENDING,
   });
   readonly selectedIds = observableArray<string>();
   readonly viewConfigured = observable(false);
   readonly cellFocus = observable<GridCellFocus | null>(null);
   readonly noProjectsMessage = pureComputed(() => {
      return this.filterChips().length ? "No Matching Projects" : "No Projects";
   });
   readonly paddingAroundColumns = pureComputed(() => {
      return this.canEditProjectDetails ? 8 : 16;
   });
   readonly columnManager = observable<GridColumnManager<SerializedProjectListProject> | null>(
      null,
   );

   // Filter customization.
   readonly chipFilterMediator = new ChipFilterMediator();
   readonly labeledFilterOptions = pureComputed(() => this.createLabeledFilterOptions());
   readonly defaultChips: Filter[] = [
      {
         type: "select",
         customFieldId: null,
         filterName: "Status",
         property: "status",
         value: "active",
         valueName: "Active",
         negation: false,
      },
   ];
   readonly filterChips: ObservableArray<Filter>;
   readonly searchQuery = observable<string | null>(null);

   private readonly integratedFields: Observable<Map<string, IntegratedField>> = observable(
      new Map(),
   );
   private readonly groupEntities = observable<GetGroupEntitiesData | null>();
   private isSelectedGroupIdChanging = false;

   //#region Batch Delete
   readonly batchDeleteValidator: BatchDeleteValidator<SerializedProjectListProject> = (
      selectedProjects,
   ) => {
      return {
         conflicts: [],
         valid: selectedProjects,
      };
   };

   readonly batchDeleteAction = async (
      stagedForDeletion: SerializedProjectListProject[],
   ): Promise<void> => {
      const deletedIds = await this.store().batchDeleteRows(stagedForDeletion);
      this.selectedIds.removeAll(deletedIds);
   };

   private readonly batchDeleteModalNote: string =
      "Are you absolutely sure you want to permanently delete the selected Projects? Doing so will truncate any current Assignments,  as well as cancel all future Assignments and open Requests for these projects.";
   //#endregion

   // Batch editor customization.
   readonly batchEditors = pureComputed(() => {
      return this.store()
         .batchEditFields(this.isFieldEditable.bind(this))
         .concat(this.columnManager()!.getCustomColumnBatchEditors());
   });

   readonly conflictModalColumnGroups = pureComputed(() => {
      return this.createRowIdentifierColumnGroups();
   });

   // Shared observables.
   readonly selectedProjects = pureComputed<SerializedProjectListProject[]>(() => {
      return this.selectedIds()
         .map(
            (id) =>
               this.store()
                  .rows()
                  .find((project) => project.id == id)!,
         )
         .filter((project) => project);
   });

   readonly projectsCountText = pureComputed(() => {
      if (this.store().loadingState() == LoadingState.INITIAL) return "Showing ...";
      const selectedCount = this.selectedProjects().length;
      const totalPossible = this.store().totalPossible();
      if (this.columnManager()!.columnGroups().length === 0 || totalPossible === 0)
         return "Showing 0";
      if (selectedCount === totalPossible) {
         return `All ${totalPossible} Projects Selected`;
      }
      if (selectedCount > 0 && totalPossible > 0) {
         return `${selectedCount}/${totalPossible} Selected`;
      }
      return `Showing ${totalPossible}`;
   });

   private readonly checkboxColumnGroupManager: CheckboxColumnGroupManager<SerializedProjectListProject>;
   private readonly subscriptions: Subscription[] = [];

   // TODO: Remove when ENABLE_BATCH_DELETE flag is no longer needed
   private readonly batchDeleteEnabled: boolean = Flag.ENABLE_BATCH_DELETE;

   private readonly reactEnabled: boolean;
   private reactRoot: Root | null;

   constructor(queryParams: Record<string, string | boolean> | null = {}) {
      super(reactTemplate(), "List");

      this.reactEnabled = true;
      this.reactRoot = null;
      this.filterChips = observableArray();

      this.store = observable(this.createStore({ startFromCurrentCursor: true }));
      this.checkboxColumnGroupManager = new CheckboxColumnGroupManager({
         selectedIds: this.selectedIds,
         allIds: pureComputed<string[]>(() => {
            return this.store()
               .rows()
               .map((project) => project.id);
         }),
      });
      if (!this.reactEnabled) {
         // Only fetch data if we are in the KnockOut experience
         // Create project roles configuration based on permissions.
         let projectRolesConfig: ProjectRolesConfig<SerializedProjectListProject> | null = null;
         if (authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ROLES)) {
            projectRolesConfig = {
               valueExtractor: (project, positionId) =>
                  this.projectRolesExtractor(project, positionId),
            };
            if (authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_ROLES)) {
               projectRolesConfig = {
                  ...projectRolesConfig,
                  groupIdsProvider: (project) => new Set(project.group_ids),
                  saveProvider: (projects, update) =>
                     this.store().updateProjectRoles(projects, update),
               };
            }
         }

         this.columnManager(
            new GridColumnManager({
               listViewType: PeopleStore.ListViewType.Projects,
               templates: this.createColumnTemplates(),
               customFieldConfig: {
                  fieldEntity: ColumnEntityType.PROJECTS,
                  // NOTE: Integrated fields use the custom field ID, while sensitive fields use the custom field property integration name.
                  isEditableProvider: (meta) =>
                     this.isFieldEditable(meta.field_property) &&
                     this.isFieldEditable(meta.field_id),
                  isVisibleProvider: (columnHeader) => this.customFieldIsVisible(columnHeader),
                  valueExtractor: (project, columnHeader) =>
                     this.customFieldValueExtractor(project, columnHeader),
                  ...(this.canEditProjectDetails
                     ? {
                          fieldEntity: ColumnEntityType.PROJECTS,
                          saveProvider: (rows, columnHeader, value) => {
                             return this.store().updateCustomFields(
                                rows,
                                columnHeader.meta()!,
                                value,
                             );
                          },
                       }
                     : {}),
               },
               projectRolesConfig,
               hasSortableCustomColumns: true,
            }),
         );

         // Check if we want to load from saved view or not.
         if (queryParams && queryParams.viewId) {
            // Resume saved view.
            this.columnManager()!.load(true);
            this.loadSavedView(queryParams.viewId as string);
         } else {
            const viewOptions: { [key: string]: any } = {};

            if (queryParams && (queryParams.sortBy || queryParams.sortDirection)) {
               const sortOrder = this.sortOrder();
               viewOptions["sortOrder"] = {
                  columnKey: queryParams.sortBy || sortOrder.columnKey,
                  direction: queryParams.sortDirection
                     ? queryParams.sortDirection == Order.DESCENDING
                        ? Order.DESCENDING
                        : Order.ASCENDING
                     : sortOrder.direction,
               };
            }
            if (queryParams && ValidationUtils.validateInput(queryParams.query)) {
               viewOptions["search"] = decodeURIComponent(queryParams.query);
            }

            viewOptions["filters"] = BrowserStorageUtils.getPageFilterChips() || this.defaultChips;

            this.setupViewConfig(viewOptions);
            this.columnManager()!.load();
         }

         this.loadGroupEntities();
         this.loadIntegratedFields();
      }
   }

   addedToParent(): void {
      if (this.reactEnabled) {
         this.reactRoot = renderReactComponent("react-project-list-mount", "ProjectListContainer", {
            setTitle: (title: string) => this.setTitle(title),
         });
      }
   }

   removedFromParent(): void {
      if (this.reactRoot) {
         this.reactRoot.unmount();
      }
   }

   async createProject({
      project,
      callback,
   }: {
      project: CreateProjectPayload;
      callback: (err: Error | null, data?: { id: string | null }) => void;
   }): Promise<void> {
      project.est_end_date = project.est_end_date ?? null;
      project.job_number = project.job_number ?? null;
      project.project_type = project.project_type ?? null;
      project.start_date = project.start_date ?? null;
      project.timezone = project.timezone ?? null;
      project.bid_rate = project.bid_rate != null ? Number(project.bid_rate) : null;
      let result: CreateProjectResponse;
      try {
         result = await ProjectStore.createProject(project).payload;
         callback(null, result.data);
      } catch (err) {
         callback(err as Error);
      }
   }

   onNewProjectButtonClicked = (): void => {
      const modal = new Modal();
      modal.setPanes([new CreateProjectPane()]);
      modalManager.showModal<CreateProjectPayload>(
         modal,
         null,
         {},
         (modal, modalStatus, observableData) => {
            if (modalStatus == "cancelled") return;
            const project = observableData.data;
            this.createProject({
               project,
               callback: (err, data) => {
                  if (err != null || data == null) {
                     return notificationManagerInstance.show(
                        new Notification({
                           icon: Icons.WARNING,
                           text: "An unexpected error occurred when attempting to create the project.",
                           duration: 5000,
                        }),
                     );
                  }
                  router.navigate(
                     null,
                     `/groups/${authManager.selectedGroupId()}/projects/${data.id}`,
                  );
               },
            });
         },
      );
   };

   onExportClicked = (): void => {
      const queryParams = this.createQueryParams();
      const pane = new ListViewExportModalPane(
         {
            ...queryParams,
            group_id: authManager.selectedGroupId() || "my-groups",
            column_headers: this.columnManager()
               ?.getActiveColumnHeaders()
               .map((header) => {
                  const value = header.allToJson();
                  delete value.baggage;
                  // Make sure null values are undefined.
                  if (value.key_id == null) {
                     delete value.key_id;
                  }
                  return value;
               }),
            display_last_names_first:
               authManager.authedUser()?.preferences()?.displayLastNamesFirst() ?? false,
         },
         "Project List Report", // title
         "project-list-report", // reportTargetPath
         ReportType.PROJECT_LIST, // reportType
      );
      const modal = new Modal();
      modal.setPanes([pane]);
      modalManager.showModal(modal, null, { class: "list-view-export-modal-pane" });
   };

   dispose(next: (() => void) | null): void {
      if (!this.reactEnabled) {
         this.subscriptions.forEach((s) => s.dispose());
         this.columnManager()!.dispose();
         this.checkboxColumnGroupManager.dispose();
      }
      if (next) next();
   }

   private setupViewConfig = (options: {
      sortOrder?: { columnKey: string; direction: Order };
      filters?: Filter[];
      search?: string;
      columnHeaders?: ColumnHeader[];
   }) => {
      if (options.sortOrder) {
         this.sortOrder(options.sortOrder);
      }
      if (options.filters) {
         this.filterChips(options.filters);
         setTimeout(() => {
            this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));
         }, 0);
      }

      if (options.search) {
         this.searchQuery(options.search);
      }

      if (options.columnHeaders) {
         // This should only get hit by saved views.
         this.columnManager()!.updateColumnHeaders(options.columnHeaders, true);
      }

      this.reload({ startFromCurrentCursor: false });
      this.setupSubscriptions();
   };

   private setupSubscriptions = () => {
      this.subscriptions.push(
         authManager.selectedGroupId.subscribe(this.onSelectedGroupIdChanged, this),
         this.filterChips.subscribe(this.onFilterChipsChanged, this),
         this.sortOrder.subscribe(this.onSortOrderChanged, this),
         this.checkboxColumnGroupManager.hasAllChecked.subscribe(
            this.onHasAllRowsCheckedChanged,
            this,
         ),
         this.searchQuery.subscribe(this.onSearchQueryChanged, this),
      );
   };

   private async loadSavedView(savedViewId: string) {
      const savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload;
      const savedView = savedViewPayload.data;
      if (savedView == null) return;

      // TODO: Check that the view is valid.

      this.setTitle(savedView.name);

      const viewOptions: { [key: string]: any } = {};

      if (savedView.view_config.sort_by && savedView.view_config.sort_direction) {
         viewOptions["sortOrder"] = {
            columnKey: savedView.view_config.sort_by,
            direction: savedView.view_config.sort_direction,
         };
      }

      if (savedView.chip_filters) {
         viewOptions["filters"] = savedView.chip_filters.map((item: any) => {
            return Format.snakeCaseObjectToCamelCase(item);
         });
      }

      if (savedView.search) {
         viewOptions["search"] = savedView.search;
      }

      if (savedView.view_config.column_headers) {
         const headers = savedView.view_config.column_headers;
         viewOptions["columnHeaders"] = headers.map((item: ColumnHeaderData) => {
            return new ColumnHeader(item);
         });

         /**
          * Minor hackaround:
          * backupProjectRoleOptions are only used in the scenario that the normal project
          * role options have not had the chance to load yet.
          * Without this code, a race condition may occur that allows the saved view to
          * load without waiting for roleTemplates within columnManager to be set, causing
          * project role columns not to load with the saved view.
          */
         const backupProjectRoleOptions: GetProjectAppliedRoleOptionsData[] = headers
            .filter((item: ColumnHeaderData) => {
               return item.key === "positions";
            })
            .map<GetProjectAppliedRoleOptionsData>((item: ColumnHeaderData) => {
               return {
                  position_id: item.key_id!,
                  position_name: item.name!,
               };
            });
         this.columnManager()!.maybeSetRoleTemplates(backupProjectRoleOptions);
      }

      this.setupViewConfig(viewOptions);
   }

   private async loadGroupEntities() {
      if (Flag.ENABLE_PROJECT_LIST_CORE) {
         const positionOptions: ValueSet[] = [];
         const peopleOptions: ValueSet[] = [];
         const tagOptions: ValueSet[] = [];
         const categorizedTagOptions: Record<string, ValueSet[]> = { Uncategorized: [] };
         if (authManager.companyModules()?.tagCategories) {
            categorizedTagOptions;
         }
         const projectsCustomFieldFilters: SerializedCustomField[] = [];
         const query = {
            ...(authManager.selectedGroupId() == "my-groups"
               ? {}
               : { group_id: authManager.selectedGroupId() }),
         };

         const loadPositions = async () => {
            const positionStream = await PositionStore.findPositionsStream(query).stream;
            for await (const position of positionStream) {
               positionOptions.push(
                  new ValueSet({
                     name: position.name,
                     color: position.color,
                     value: position.id,
                  }),
               );
            }
         };

         const loadPersons = async () => {
            const personStream = await PersonStore.findPeopleStream(query).stream;
            for await (const person of personStream) {
               peopleOptions.push(
                  new ValueSet({
                     name: authManager.authedUser()?.preferences()?.displayLastNamesFirst()
                        ? `${person.name.last}, ${person.name.first}`
                        : `${person.name.first} ${person.name.last}`,
                     value: person.id,
                  }),
               );
            }
         };
         const loadTags = async () => {
            const tagStream = await TagStore.findTagsStream(query).stream;
            if (authManager.companyModules()?.tagCategories) {
               for await (const tag of tagStream) {
                  if (tag.categories.length != 0) {
                     for (const category of tag.categories) {
                        const valueSet = new ValueSet({
                           name: tag.name,
                           color: tag.color,
                           value: tag.id,
                        });
                        if (categorizedTagOptions[category] == null)
                           categorizedTagOptions[category] = [];
                        categorizedTagOptions[category].push(valueSet);
                     }
                  } else {
                     categorizedTagOptions["Uncategorized"].push(
                        new ValueSet({ name: tag.name, value: tag.id, color: tag.color }),
                     );
                  }
               }
            } else {
               for await (const tag of tagStream) {
                  tagOptions.push(
                     new ValueSet({ color: tag.color, name: tag.name, value: tag.id }),
                  );
               }
            }
         };
         const loadCustomFields = async () => {
            const customFieldStream = await CustomFieldStore.findCustomFieldsStream({
               is_on_entities: [CustomFieldEntity.PROJECT],
            }).stream;
            for await (const customField of customFieldStream) {
               projectsCustomFieldFilters.push(customField);
            }
         };
         try {
            await Promise.all([loadPositions(), loadPersons(), loadTags(), loadCustomFields()]);

            this.groupEntities({
               positionOptions,
               peopleOptions,
               projectsCustomFieldFilters,
               ...(authManager.companyModules()?.tagCategories
                  ? { categorizedTagOptions }
                  : { tagOptions }),
            });
         } catch (e) {
            Bugsnag.notify(e as NotifiableError, (event) => {
               event.context = "project-list_loadGroupEntities";
               event.addMetadata(
                  BUGSNAG_META_TAB.USER_DATA,
                  buildUserData(authManager.authedUser()!, authManager.activePermission),
               );
               event.addMetadata("groupEntities", this.groupEntities);
            });
            return notificationManagerInstance.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "An unexpected error prevented the filters from loading.",
               }),
            );
         }
      } else {
         const entities = ["positions", "people"];
         if (authManager.companyModules()?.tagCategories) {
            entities.push("categorized-tags");
         } else {
            entities.push("tags");
         }
         if (authManager.companyModules()?.customFields) {
            entities.push("projects_custom_field_filters");
         }
         // TODO: change to use core CompanyStore.getCompanyEntityOptions with group filter
         legacyGroupStore.getGroupEntities(authManager.selectedGroupId(), entities, (err, data) => {
            if (err) {
               return notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "An unexpected error prevented the filters from loading.",
                  }),
               );
            }
            this.groupEntities(data);
         });
      }
   }

   private async loadIntegratedFields() {
      const response = await CompanyStore.getIntegratedFields().payload;
      const integratedFields = new Map<string, IntegratedField>();

      if (Array.isArray(response.data.projects_integrated_fields)) {
         response.data.projects_integrated_fields.forEach((integratedField) => {
            integratedFields.set(integratedField.property, integratedField);
         });
      }

      this.integratedFields(integratedFields);
   }

   private onSelectedGroupIdChanged(groupId: string) {
      // Set isSelectedGroupIdChanging to notify the rest of the listeners that
      // the group ID is changing and they should respond accordingly.
      this.isSelectedGroupIdChanging = true;

      // Update the filter chips from browser storage. Specify the path manually because the URL
      // has not yet been updated.
      this.filterChips(
         BrowserStorageUtils.getPageFilterChips(
            `${BrowserStorageUtils.BrowserLocalStorageKey.CHIP_FILTERS}_/groups/${groupId}/projects`,
         ) || this.defaultChips,
      );
      this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));

      // Update the available project roles, since these are the only other
      // column option with Group restrictions.
      this.columnManager()!.loadProjectRoles();

      // Create a new store to use the new group ID and filter chips.
      this.reload({ startFromCurrentCursor: true });
      this.loadGroupEntities();
      this.isSelectedGroupIdChanging = false;
   }

   private onFilterChipsChanged(filterChips: Filter[]) {
      if (!this.isSelectedGroupIdChanging) {
         this.reload({ startFromCurrentCursor: false });
         BrowserStorageUtils.storePageFilterChips(filterChips);
      }
   }

   private onSortOrderChanged(sortOrder: GridSortOrder) {
      router.updateUrlQueryParam(App.RouteName.PROJECT_LIST, "sortBy", sortOrder.columnKey);
      router.updateUrlQueryParam(
         App.RouteName.PROJECT_LIST,
         Order.ASCENDING,
         (sortOrder.direction == Order.ASCENDING).toString(),
      );
      this.reload({ startFromCurrentCursor: false });
   }

   private onHasAllRowsCheckedChanged(hasAllRowsChecked: boolean) {
      if (hasAllRowsChecked) {
         this.store().loadAll();
      } else {
         this.store().cancelLoadAll();
      }
   }

   showSaveViewModal = (): void => {
      // Not using query params since it's on legacy pattern still.
      const modal = new Modal();
      const pane = new SaveViewPane(App.PageName.PROJECT_LIST, {
         search: this.searchQuery(),
         columnHeaders: this.columnManager()!.getActiveColumnHeaders(),
         filters: this.filterChips(),
         sortBy: this.sortOrder().columnKey,
         sortDirection: this.sortOrder().direction,
      });
      modal.setPanes([pane]);
      modalManager.showModal(modal, null, { class: "save-view-modal" });
   };

   private onSearchQueryChanged(query: string | null) {
      if (ValidationUtils.validateInput(query)) {
         router.updateUrlQueryParam(
            App.RouteName.PROJECT_LIST,
            "query",
            encodeURIComponent(query.trim().toLowerCase()),
         );
      } else {
         router.removeQueryParam(App.RouteName.PROJECT_LIST, "query");
      }
   }

   private createLabeledFilterOptions(): LabeledFilterOptions {
      const groupEntities = this.groupEntities();
      if (!groupEntities) return {};

      const filterOptions: LabeledFilterOptions = {
         Status: observable<any>({
            property: "status",
            values: [
               new ValueSet({ name: "Active", value: "active" }),
               new ValueSet({ name: "Pending", value: "pending" }),
               new ValueSet({ name: "Inactive", value: "inactive" }),
            ],
         }),
         "Start Date": observable(buildDateFilterInstance("start_date")),
         "Est. End Date": observable(buildDateFilterInstance("est_end_date")),
      };

      if (authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)) {
         filterOptions["Bid Rate"] = observable<any>({
            property: "bid_rate",
            disableSearch: true,
            classifiers: [
               { listLabel: "< (Less Than)", chipLabel: "<", value: "<" },
               { listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<=" },
               { listLabel: "= (Equal To)", chipLabel: "=", value: "=" },
               { listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">=" },
               { listLabel: "> (Greater Than)", chipLabel: ">", value: ">" },
            ],
            type: "number",
         });
      }

      if (this.isSensitiveFieldVisible("percent_complete")) {
         filterOptions["Percent Complete"] = observable<any>({
            property: "percent_complete",
            disableSearch: true,
            classifiers: [
               { listLabel: "< (Less Than)", chipLabel: "<", value: "<" },
               { listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<=" },
               { listLabel: "= (Equal To)", chipLabel: "=", value: "=" },
               { listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">=" },
               { listLabel: "> (Greater Than)", chipLabel: ">", value: ">" },
            ],
            type: "number",
         });
      }

      if (authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)) {
         if (groupEntities.tagOptions && groupEntities.tagOptions.length) {
            filterOptions["Tags"] = observable<any>({
               property: "tag_instances",
               type: "multi-select",
               values: Format.keyableSort(groupEntities.tagOptions, "name"),
            });
         } else if (groupEntities.categorizedTagOptions) {
            filterOptions["Tags"] = observable<any>({
               property: "tag_instances",
               type: "multi-select",
               classifiers: Object.keys(groupEntities.categorizedTagOptions)
                  .map((key) => ({ listLabel: key, chipLabel: null, value: key }))
                  .sort((a, b) =>
                     a.listLabel.toLowerCase().localeCompare(b.listLabel.toLowerCase()),
                  ),
               classifierPaneName: "Tag Category",
               values: groupEntities.categorizedTagOptions,
               backEnabled: true,
            });
         }
      }

      if (groupEntities.projectsCustomFieldFilters) {
         accumulateCustomFieldChipFilters(filterOptions, groupEntities.projectsCustomFieldFilters, {
            propertyName: "custom_fields",
            sensitiveFields: authManager.projectsSensitiveFields(),
            canViewSensitiveFields: authManager.checkAuthAction(
               PermissionLevel.Action.VIEW_PROJECT_SENSITIVE,
            ),
            canViewFinancials: authManager.checkAuthAction(
               PermissionLevel.Action.VIEW_PROJECT_FINANCIALS,
            ),
         });
      }

      if (groupEntities.positionOptions?.length && groupEntities.peopleOptions?.length) {
         const rolesFilter = createProjectRolesFilterOption({
            positionOptions: groupEntities.positionOptions,
            peopleOptions: groupEntities.peopleOptions,
         });
         filterOptions["Project Roles"] = rolesFilter;
      }
      return filterOptions;
   }

   private createColumnTemplates(): Array<ColumnTemplate<SerializedProjectListProject>> {
      const templateVisitors: Array<{
         visit: () => boolean;
         accept: () => ColumnTemplate<SerializedProjectListProject, unknown, unknown, any>; // TODO: Remove any.
      }> = [
         {
            visit: () => this.hasBatchEditPermssions,
            accept: () => ({
               ...this.checkboxColumnGroupManager.columnGroup.columns[0],
               isFixed: true,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: {
                  name: "link-to-procore-heading-cell",
                  textName: "Linked to Procore Project",
                  params: {},
               },
               key: "has_procore_mapping",
               width: 40,
               isResizable: false,
               isSortable: true,
               cellFactory: LinkToProcoreCell.factory((project) => ({
                  entityType: "project",
                  entityId: project.id,
                  procoreId: project.procore_id,
               })),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Name",
               key: "name",
               width: ColumnWidthDefault.PROJECT_NAME,
               minWidth: ColumnWidthMin.PROJECT_NAME,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...LinkedTextCell.columnProviders((project) => ({
                  text: project.name,
                  href: `/groups/${authManager.selectedGroupId()}/projects/${project.id}`,
               })),
               editorFactory: ({ row }) => this.store().nameEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("name")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Created By",
               key: "creator_name",
               width: 155,
               isDefault: false,
               isResizable: true,
               ...TextCell.columnProviders((project) => {
                  return project.creator_name != null
                     ? authManager.authedUser()?.preferences()?.displayLastNamesFirst
                        ? `${project.creator_name.last}, ${project.creator_name.first}`
                        : `${project.creator_name.first} ${project.creator_name.last}`
                     : "";
               }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Created",
               key: "created_at",
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) =>
                  this.formatTimestamp(project.created_at ?? null),
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Color",
               key: "color",
               width: 65,
               minWidth: 50,
               isDefault: true,
               isResizable: true,
               ...ColorCell.columnProviders((project) => project.color),
               editorFactory: ({ row }) => this.store().colorEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("color")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Project #",
               key: "job_number",
               width: ColumnWidthDefault.PROJECT_NUMBER,
               minWidth: ColumnWidthMin.PROJECT_NUMBER,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.job_number || ""),
               editorFactory: ({ row }) => this.store().jobNumberEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("job_number")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("address_1"),
            accept: () => ({
               header: "Address",
               key: "address_1",
               width: ColumnWidthDefault.ADDRESS_1,
               minWidth: ColumnWidthMin.ADDRESS_1,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.address_1 || ""),
               editorFactory: ({ row }) => this.store().addressEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("address_1")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("city_town"),
            accept: () => ({
               header: "City",
               key: "city_town",
               width: ColumnWidthDefault.CITY_TOWN,
               minWidth: ColumnWidthMin.CITY_TOWN,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.city_town || ""),
               editorFactory: ({ row }) => this.store().cityEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("city_town")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("state_province"),
            accept: () => ({
               header: "State",
               key: "state_province",
               width: ColumnWidthDefault.STATE_PROVINCE,
               minWidth: ColumnWidthMin.STATE_PROVINCE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.state_province || ""),
               editorFactory: ({ row }) => this.store().stateEditorFactory([row]),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("state_province")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("zipcode"),
            accept: () => ({
               header: "Postal",
               key: "zip_code",
               width: ColumnWidthDefault.ZIPCODE,
               minWidth: ColumnWidthMin.ZIPCODE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.zipcode || ""),
               editorFactory: ({ row }) => this.store().postalEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("zipcode")),
            }),
         },
         {
            visit: () => this.canViewProjectTags,
            accept: () => ({
               header: "Tags",
               key: "tag_instances",
               width: ColumnWidthDefault.TAGS,
               minWidth: ColumnWidthMin.TAGS,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TagsCell.columnProviders<SerializedProjectListProject>((project) => ({
                  tagInstances: (project.tag_instances ?? []).map(
                     (instance: SerializedProjectListTagInstance) => ({
                        name: instance.tag.name,
                        abbreviation: instance.tag.abbreviation,
                        color: instance.tag.color,
                        expirationState: TagExpirationState.NOT_EXPIRING_SOON,
                     }),
                  ),
               })),
               editorFactory: ({ row }) => this.store().tagInstancesEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.canEditProjectTags),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Status",
               key: "status",
               width: ColumnWidthDefault.PROJECT_STATUS,
               minWidth: ColumnWidthMin.PROJECT_STATUS,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => Format.capitalize(project.status)),
               editorFactory: ({ row }) => this.store().statusEditorFactory([row], true),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("status")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("address_2"),
            accept: () => ({
               header: "Address 2",
               key: "address_2",
               width: ColumnWidthDefault.ADDRESS_2,
               minWidth: ColumnWidthMin.ADDRESS_2,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.address_2 || ""),
               editorFactory: ({ row }) => this.store().address2EditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("address_2")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("country"),
            accept: () => ({
               header: "Country",
               key: "country",
               width: ColumnWidthDefault.COUNTRY,
               minWidth: ColumnWidthMin.COUNTRY,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.country || ""),
               editorFactory: ({ row }) => this.store().countryEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("country")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Daily End Time",
               key: "daily_end_time",
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => this.formatTime(project.daily_end_time)),
               editorFactory: ({ row }) => this.store().dailyEndTimeEditorFactory([row]),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("daily_end_time")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Daily Start Time",
               key: "daily_start_time",
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => this.formatTime(project.daily_start_time)),
               editorFactory: ({ row }) => this.store().dailyStartTimeEditorFactory([row]),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("daily_start_time")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Timezone",
               key: "timezone",
               width: ColumnWidthDefault.TIMEZONE,
               minWidth: ColumnWidthMin.TIMEZONE,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.timezone || ""),
               editorFactory: ({ row }) => this.store().timezoneEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("timezone")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Start Date",
               key: "start_date",
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => {
                  if (project.start_date) {
                     const startDateObj = new Date(project.start_date);
                     if (startDateObj.getTimezoneOffset() > 0) {
                        startDateObj.setHours(
                           startDateObj.getHours() + startDateObj.getTimezoneOffset() / 60,
                        );
                     }
                     return this.formatTimestamp(startDateObj.getTime());
                  } else {
                     return "";
                  }
               }),
               editorFactory: ({ row }) => this.store().startDateEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("start_date")),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Est. End Date",
               key: "est_end_date",
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => {
                  if (project.est_end_date) {
                     const estEndDateObj = new Date(project.est_end_date);
                     if (estEndDateObj.getTimezoneOffset() > 0) {
                        estEndDateObj.setHours(
                           estEndDateObj.getHours() + estEndDateObj.getTimezoneOffset() / 60,
                        );
                     }
                     return this.formatTimestamp(estEndDateObj.getTime());
                  } else {
                     return "";
                  }
               }),
               editorFactory: ({ row }) => this.store().endDateEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("est_end_date")),
            }),
         },
         {
            visit: () =>
               authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS),
            accept: () => ({
               header: "Est Avg Rate",
               key: "bid_rate",
               width: 85,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => {
                  const bidRate = project.bid_rate;
                  return bidRate != null ? `${Format.formatCurrency(bidRate)}/hr` : "";
               }),
               editorFactory: ({ row }) => this.store().bidRateEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("bid_rate")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("customer_name"),
            accept: () => ({
               header: "Customer",
               key: "customer_name",
               width: 155,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.customer_name || ""),
               editorFactory: ({ row }) => this.store().customerNameEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("customer_name")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("percent_complete"),
            accept: () => ({
               header: "Percent Complete",
               key: "percent_complete",
               width: 155,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) =>
                  project.percent_complete != null ? `${project.percent_complete}%` : "",
               ),
               editorFactory: ({ row }) => this.store().percentCompleteEditorFactory([row]),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("percent_complete")),
            }),
         },
         {
            visit: () => this.isSensitiveFieldVisible("type"),
            accept: () => ({
               header: "Project Type",
               key: "type",
               width: 125,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((project) => project.project_type || ""),
               editorFactory: ({ row }) => this.store().projectTypeEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("type")),
            }),
         },
         {
            visit: () =>
               authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_WAGE_OVERRIDES),
            accept: () => ({
               header: "Wage Overrides",
               key: "wage_overrides",
               width: 200,
               isSortable: true,
               isResizable: true,
               ...MultilineTextCell.columnProviders<SerializedProjectListProject>((project) => {
                  return {
                     values: (project.wage_overrides ?? []).map(
                        (wageOverride: SerializedWageOverride & { position_name: string }) => ({
                           text: `${wageOverride.position_name} - $${wageOverride.rate}/hr`,
                        }),
                     ),
                  };
               }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               width: 120,
               isDefault: false,
               isSortable: false,
               isResizable: true,
               header: "Groups",
               key: PROJECTS.GROUP_IDS,
               ...GroupsEditor.getColumnProviders({
                  authManager,
                  groupIdsSelector: (project) => project.group_ids,
               }),
               cursorStateProvider: () =>
                  this.isFieldEditable("group_ids")
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().groupsInlineEditorFactory([row])
                     : DisabledGroupsEditor.create({
                          errorText: "Groups cannot be edited for this project.",
                          groupNameList: authManager.getSortedGroupNames(new Set(row.group_ids)),
                       }),
            }),
         },
      ];
      return templateVisitors
         .filter((visitor) => visitor.visit())
         .map((visitor) => visitor.accept());
   }

   private reload(params: { startFromCurrentCursor: boolean }) {
      this.store(this.createStore(params));
      // This is an async control flow for the inital load
      // and then is useless past that.
      this.viewConfigured(true);
      this.selectedIds([]);
   }

   private createStore({ startFromCurrentCursor }: { startFromCurrentCursor: boolean }) {
      return new ProjectsList2GridStore({
         queryParams: this.createQueryParams(),
         cacheKey: `${authManager.selectedGroupId()}-projects-list`,
         startFromCurrentCursor,
         errorModalColumnGroups: this.createRowIdentifierColumnGroups(),
         columnManager: this.columnManager,
      });
   }

   private createRowIdentifierColumnGroups(): Array<GridColumnGroup<SerializedProjectListProject>> {
      return createColumnGroupForEach({
         header: "Project",
         key: "name",
         width: 300,
         cellFactory: TextCell.factory((project) => project.name),
      });
   }

   private createQueryParams(): FindProjectsPaginatedQueryParams {
      const search = this.searchQuery();
      const customFieldId = this.sortOrder().columnKey.split(":")[1];
      const queryParams = {
         filters: serializedFilters(this.filterChips(), "name"),
         limit: ITEMS_PER_REQUEST,
         sort_by: (customFieldId ? "custom_field" : this.sortOrder().columnKey) as
            | NonIndexedFindProjectsSortBy
            | IndexedFindProjectsSortBy,
         sort_direction: this.sortOrder().direction,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         ...(ValidationUtils.validateInput(search) ? { search } : {}),
         ...(authManager.selectedGroupId() == "my-groups"
            ? {}
            : { group_id: authManager.selectedGroupId() }),
         ...(customFieldId ? { custom_field_id: customFieldId } : {}),
      };
      return queryParams;
   }

   private createExportQueryParams(): GetFilteredProjectsQuery {
      const search = this.searchQuery();
      return {
         filters: serializeLegacyFilters(this.filterChips()),
         sortBy: this.sortOrder().columnKey,
         sortAscending: this.sortOrder().direction == Order.ASCENDING,
         limit: ITEMS_PER_REQUEST,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         ...(ValidationUtils.validateInput(search) ? { search } : {}),
      };
   }

   private customFieldIsVisible(columnHeader: ColumnHeader): boolean {
      const meta = columnHeader.meta();
      if (!meta) return false;
      if (meta.field_type == "currency") {
         if (!authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)) {
            return false;
         }
         // Intentionally fall through to check sensitivity even after passing
         // the financials check.
      }
      if (authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE)) {
         return true;
      }
      return !authManager.projectsSensitiveFields().includes(meta.field_property);
   }

   private customFieldValueExtractor(
      project: SerializedProjectListProject,
      columnHeader: ColumnHeader,
   ) {
      const fieldId = columnHeader.meta()!.field_id;
      return project.custom_fields!.find((f) => f.field_id == fieldId)?.value ?? null;
   }

   private projectRolesExtractor(
      project: SerializedProjectListProject,
      positionId: string,
   ): ProjectRole[] {
      return (project.roles || [])
         .filter((role) => role.job_title_id == positionId && role.assignee_name)
         .map((role: any) => ({
            id: role.id,
            personName: role.assignee_name,
            personId: role.person_id,
         }));
   }

   private formatTimestamp(timestamp: number | null) {
      return timestamp != null
         ? DateUtils.formatDate(new Date(timestamp), defaultStore.getDateFormat())
         : "";
   }

   private formatTime(time: number) {
      const option = DefaultStore.Data.TIME_OPTIONS.find((option) => option.value == time);
      return option ? option.name : "";
   }

   private isSensitiveFieldVisible(field: string) {
      return (
         authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE) ||
         !authManager.projectsSensitiveFields().includes(field)
      );
   }

   private isFieldEditable(field: string) {
      return (
         this.canEditProjectDetails &&
         this.isSensitiveFieldEditable(field) &&
         this.isIntegratedFieldEditable(field)
      );
   }

   private isIntegratedFieldEditable(field: string) {
      const integratedFields = this.integratedFields();
      const integratedField = integratedFields.get(field) || { locked: false };
      return !integratedField.locked;
   }

   private isSensitiveFieldEditable(field: string) {
      return this.canEditSensitive || !authManager.projectsSensitiveFields().includes(field);
   }
}
